import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactWrap = styled.div`
  font-family: 'Teko', sans-serif;
  font-size: 1.5em;
  letter-spacing: 1px;
  padding: 0 20px;
  width: 475px;
  max-width: 85vw;

  label {
    display: block;
    margin: 5px 0;
    cursor: pointer;
  }

  input,
  textarea {
    width: 96%;
    padding: 2px 2%;
    border: 1px solid #000;
    font-size: 24px;
  }

  textarea {
    resize: vertical;
  }

  input,
  textarea,
  .select {
    border: 1px solid #000;
    font-family: 'Teko', sans-serif;
    outline: none;
    border-radius: 0;
  }

  .css-1s2u09g-control {
    border: none;
    border-radius: 0;
  }

  .css-1pahdxg-control {
    border: none;
    border-radius: 0;
    box-shadow: none;

    &:hover {
      border: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
  span {
    color: red;
  }

  .error {
    border: 1px solid #b71c1c;
    box-shadow: 0 0 5px 0.5px #b71c1c;
  }
`;

export const Button = styled.button`
  position: relative;
  color: var(--white);
  padding: 2px 0;
  border: none;
  border-radius: 0;
  font-size: 24px;
  font-family: 'Teko', sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-out;
  width: 100%;
  margin-top: 20px;
  z-index: 0;

  &:hover {
    color: #ffeb3b;
    cursor: pointer;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    z-index: -1;
    cursor: pointer;
  }

  &::after {
    background-color: #000;
    transition: all 0.3s ease-out;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    right: 0;
  }

  &::before {
    background-color: #000;
    transition: 0.3s ease-out;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 0%;
    color: var(--white);
  }

  &.success,
  &.error,
  &.loading {
    &::before {
      width: 100%;
    }

    &::after {
      right: 100%;
    }
  }

  &.loading {
    &::before {
      background-color: #f57f17;
    }
  }

  &.success {
    &::before {
      background-color: #2e7d32;
    }
  }

  &.error {
    &::before {
      background-color: #b71c1c;
    }
  }
`;
