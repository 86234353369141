import React from 'react';

import { Text } from './intro.styles';

export const Home = () => {
  return (
    <Text>
      we are a software studio focused on creating{' '}
      <span className="marked">elegant, functional</span> apps
    </Text>
  );
};

export const Projects = () => {
  return <Text>below are projects that we have been involved in</Text>;
};

export const Contact = () => {
  return (
    <Text>
      you can send us a message below, we typically respond in under{' '}
      <span className="marked">2 hours</span>
    </Text>
  );
};
