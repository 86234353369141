import React from 'react';
import styled from 'styled-components';
import { Icons, Links } from '../helpers/utilities';

export default function About() {
  const external = 'noopener noreferrer external';
  const blank = '_blank';

  return (
    <Wrapper>
      <AboutDescription>
        Castynet is a team of{' '}
        <span className="marked">Software Developers</span> based in{' '}
        <span className="marked">Nairobi KE.</span> We are high spirited geeks
        passionate about tech. Our mission is to create{' '}
        <span className="marked">Empowering Software</span>. We are powered by
        coffee, unreal imagination &amp; attention to detail .<br /> Whether you
        are looking to put your boutique online or want a chat forum for your
        pals, the Castynet Studios will{' '}
        <span className="marked">create your app</span> on{' '}
        <span className="marked">time &amp; within your budget</span>.
      </AboutDescription>
      <Rating>
        <ListTitle>rated at</ListTitle>
        <a
          href={Links.GoogleListing}
          target={blank}
          rel={external}
          title="See Castynet's Rating on Google"
        >
          <dd>
            5/5 Star on Google <Icons.Link size="17" />
          </dd>
        </a>
      </Rating>
      <Stats>
        <ListTitle>the numbers</ListTitle>
        <a
          href={Links.Github}
          target={blank}
          rel={external}
          title="Visit Castynet's Github"
        >
          <dd>
            931,019 lines of code <Icons.Github size="20" />
          </dd>
        </a>
        <a
          href={Links.Instagram}
          target={blank}
          rel={external}
          title="Visit Castynet's Instagram"
        >
          <dd>
            1.6k Peers <Icons.Instagram size="20" />
          </dd>
        </a>
        <a
          href={Links.Twitter}
          target={blank}
          rel={external}
          title="Visit Castynet's Twitter"
        >
          <dd>
            285 Tweets <Icons.Twitter size="20" />
          </dd>
        </a>
      </Stats>
      <Services>
        <ListTitle>we love</ListTitle>
        <dd>Web Apps</dd>
        <dd>Desktop Enterprise Software</dd>
        <dd>Android Apps</dd>
        <dd>iOS Apps</dd>
      </Services>
      <Technologies>
        <ListTitle>tools we use</ListTitle>
        <dd>JavaScript</dd>
        <dd>ReactJS</dd>
        <dd>React Native</dd>
        <dd>NodeJS</dd>
        <dd>Headless CMS/s</dd>
        <dd>Firebase</dd>
        <dd>Amazon AWS</dd>
      </Technologies>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: 'Teko', sans-serif;
  letter-spacing: 1px;
  font-size: 1.4em;
`;

const AboutDescription = styled.main`
  padding: 0 20px;
  line-height: 1.5em;
  font-size: 1.1em;
  width: 800px;
  max-width: 90vw;
  margin: 0 auto;

  span {
    display: inline;
    background: var(--white);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 237, 79, 1) 75%,
      rgba(255, 235, 59, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="var(--white)",endColorstr="#ffeb3b",GradientType=1);
  }
`;

const Rating = styled.dl`
  padding: 20px 0 10px 20px;
  dd {
    display: inline-block;
    padding: 1px 10px;
    margin: 5px;
    border-radius: 3px;
    background-color: #304d5b;
    color: var(--white);
    font-weight: 300;
    transition: all 0.2s ease;
  }
  a dd:hover {
    background-color: #ff1493;
  }
`;

const ListTitle = styled.dt`
  display: inline-block;
  background-color: var(--white) !important;
  width: 120px;
  text-align: center;
  color: #304d5b !important;
  border: solid 1px #304d5b;
  padding: 1px !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
`;

const Services = styled.dl`
  padding: 10px 0 10px 20px;
  dd {
    display: inline-block;
    padding: 1px 10px;
    margin: 5px;
    border-radius: 3px;
    background-color: #304d5b;
    color: var(--white);
    font-weight: 300;
  }
  dd:hover {
    cursor: default;
  }
`;

const Technologies = styled.dl`
  padding: 10px 0 10px 20px;
  dd {
    display: inline-block;
    padding: 1px 10px;
    margin: 5px;
    border-radius: 3px;
    background-color: #304d5b;
    color: var(--white);
    font-weight: 300;
  }
  dd:hover {
    cursor: default;
  }
`;

const Stats = styled.dl`
  padding: 10px 0 10px 20px;
  dd {
    display: inline-block;
    padding: 1px 10px;
    margin: 5px;
    border-radius: 3px;
    background-color: #304d5b;
    color: var(--white);
    font-weight: 300;
    transition: all 0.2s ease;
  }
  a dd:hover {
    background-color: #ff1493;
  }
`;
