import React from 'react';

import { Link } from 'react-router-dom';

import * as Styled from './intro.styles';

import { useApp } from '../context/context';
import { Home, Projects, Contact } from './messages';
import CastynetAnimated from '../animations/castynetAnimation';

export default function Intro() {
  const { page } = useApp();

  const isContact = page === '/contact';
  const isProjects = page === '/projects';

  return (
    <Styled.Wrapper aria-label="Introduction">
      <Styled.Title aria-label="Castynet Logo" title="Castynet Logo">
        <Link to="/">
          <CastynetAnimated />
        </Link>
      </Styled.Title>
      <Styled.Description data-nosnippet>
        Empowering Software
      </Styled.Description>
      {isContact ? <Contact /> : isProjects ? <Projects /> : <Home />}
    </Styled.Wrapper>
  );
}
