import React from 'react';

import { Icons, Links } from '../helpers/utilities';
import { Year } from '../helpers/helpers';

import * as Styled from './footer.styles';

export default function Footer() {
  const external = 'noopener noreferrer external';
  const blank = '_blank';

  return (
    <Styled.Wrapper>
      <p>&#169; 2018 - {Year()} Castynet Studios - Empowering Software</p>
      <Styled.IconsWrap>
        <a
          href={Links.Github}
          target={blank}
          rel={external}
          title="Visit Castynet's Github"
        >
          <Icons.Github size="25" />
        </a>
        <a
          href={Links.Behance}
          target={blank}
          rel={external}
          title="Visit Castynet's Behance"
        >
          <Icons.Behance size="25" />
        </a>
        <a
          href={Links.Twitter}
          target={blank}
          rel={external}
          title="Visit Castynet's Twitter"
        >
          <Icons.Twitter size="25" />
        </a>
        <a
          href={Links.Pinterest}
          target={blank}
          rel={external}
          title="Visit Castynet's Pinterest"
        >
          <Icons.Pinterest size="25" />
        </a>
        <a
          href={Links.Instagram}
          target={blank}
          rel={external}
          title="Visit Castynet's Instagram"
        >
          <Icons.Instagram size="25" />
        </a>
      </Styled.IconsWrap>
    </Styled.Wrapper>
  );
}
