import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Intro from './components/intro/intro';

import Footer from './components/footer/footer';
import NotFound from './components/notFound/notFound';

import Home from './components/routes/home';
import Contact from './components/routes/contact';
import Projects from './components/routes/projects';

export default function App() {
  return (
    <>
      <Intro />
      <Switch>
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/" component={Home} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </>
  );
}
