import React, { useRef, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Icons } from '../helpers/utilities';
import About from '../about/about';

import * as Styled from './app.styles';

export default function HomePage({ location }) {
  const eAbout = useRef(null);
  const { state } = location;

  useEffect(() => {
    if (state !== undefined && state.by === 'About')
      eAbout.current.scrollIntoView(true);
  }, [state]);

  const scIntoView = (e) => {
    eAbout.current.scrollIntoView(true);
  };

  return (
    <main>
      <section aria-label="Projects">
        <Link to="/projects">
          <Styled.Title>
            <Icons.Dash size="20" />
            <Styled.LineBg>Projects &amp; Partners</Styled.LineBg>
            <Icons.Expand size="30" color="#ff1493" />
          </Styled.Title>
        </Link>
      </section>
      <section aria-label="Contact">
        <Link to="/contact">
          <Styled.Title>
            <Icons.Dash size="20" />
            <Styled.LineBg>Contact us</Styled.LineBg>
            <Icons.Expand size="30" color="#ff1493" />
          </Styled.Title>
        </Link>
      </section>
      <section aria-label="About">
        <Styled.Title onClick={() => scIntoView(eAbout)}>
          <Icons.Dash size="20" />
          <Styled.LineBg ref={eAbout}>About</Styled.LineBg>
        </Styled.Title>
        <About />
      </section>
    </main>
  );
}
