import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { useApp } from '../context/context';

import * as styled from './contact.styles';

const options = [
  {
    value: 'Web Application',
    label: 'Web Application'
  },
  {
    value: 'Android Application',
    label: 'Android Application'
  },
  {
    value: 'iOS Application',
    label: 'iOS Application'
  },
  {
    value: 'Desktop Enterprise Software',
    label: 'Desktop Enterprise Software'
  },
  {
    value: 'Not sure',
    label: 'Not sure'
  }
];

const validEmail = (str) => {
  let lastAtPos = str.lastIndexOf('@');
  let lastDotPos = str.lastIndexOf('.');
  return (
    lastAtPos < lastDotPos &&
    lastAtPos > 0 &&
    str.indexOf('@@') === -1 &&
    lastDotPos > 2 &&
    str.length - lastDotPos > 2
  );
};

const animatedComponents = makeAnimated();

export default function Contact() {
  const { message, setMessage, submitMessage, success, loading, serverErr } =
    useApp();

  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const validate = () => {
    if (message.name === '') setNameError(true);
    else setNameError(false);
    if (message.message === '') setMessageError(true);
    else setMessageError(false);
    if (!validEmail(message.email)) setEmailError(true);
    else setEmailError(false);
    if (
      message.name === '' ||
      message.message === '' ||
      !validEmail(message.email)
    )
      return false;
    else return true;
  };

  const handleSubmit = () => (validate() ? submitMessage() : setError(true));

  return (
    <>
      <Helmet>
        <title>Contact - Castynet Africa</title>
        <meta
          name="description"
          content="Get in-touch with castynet studios via +254743665574"
        />
      </Helmet>
      <styled.Wrapper>
        <styled.ContactWrap>
          <label htmlFor="name">
            Name <span className="marked">*</span>
          </label>
          <input
            className={error && nameError ? 'error' : ''}
            type="text"
            name="Name"
            id="name"
            value={message.name}
            onChange={(e) => setMessage({ ...message, name: e.target.value })}
          />
          <label htmlFor="email">
            Email <span className="marked">*</span>
          </label>
          <input
            className={error && emailError ? 'error' : ''}
            type="email"
            name="email"
            id="email"
            value={message.email}
            onChange={(e) => setMessage({ ...message, email: e.target.value })}
          />
          <label htmlFor="message">
            Message <span className="marked">*</span>
          </label>
          <textarea
            className={error && messageError ? 'error' : ''}
            name="message"
            id="message"
            rows="3"
            value={message.message}
            onChange={(e) =>
              setMessage({ ...message, message: e.target.value })
            }
          />
          <label htmlFor="appType">
            What kind of app are you interested in?
          </label>
          <Select
            components={animatedComponents}
            closeMenuOnSelect={false}
            options={options}
            isMulti
            isSearchable
            id="appType"
            placeholder="Tap to select"
            className="select"
            onChange={(e) => setMessage({ ...message, appType: e })}
          />
          <styled.Button
            type="button"
            onClick={() => handleSubmit()}
            className={
              loading
                ? 'loading'
                : success
                ? 'success'
                : error || serverErr
                ? 'error'
                : ''
            }
          >
            {loading
              ? 'Hang tight...'
              : success
              ? "Great! We'll be in touch soon"
              : error
              ? 'Sorry, kindly check your details'
              : serverErr
              ? 'Sorry, that did not work, please try again'
              : 'Get in touch'}
          </styled.Button>
        </styled.ContactWrap>
      </styled.Wrapper>
    </>
  );
}
