import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Context = React.createContext();

export const useApp = () => useContext(Context);

const msg = { name: '', email: '', phone: '', message: '', appType: '' };

export const StateProvider = (props) => {
  const [page, setPage] = useState('/');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [serverErr, setServerErr] = useState(false);
  const [message, setMessage] = useState(msg);

  const { pathname } = useLocation();

  useEffect(() => setPage(pathname), [pathname]);

  const submitMessage = () => {
    setLoading(true);
    let request = new XMLHttpRequest();

    request.open(
      'POST',
      'https://script.google.com/macros/s/AKfycbxosIYvAsr84HHV48nEGi7vA5fMuGJtu4O9DX_iEvtd1fwZbK3H_aZx2tz7CDGya8vd_w/exec'
    );

    let formData = new FormData();
    let appTypes = [];

    message.appType instanceof Array &&
      message.appType.forEach((item) => {
        appTypes.push(item.value);
      });

    formData.append('Name', message.name);
    formData.append('Email', message.email);
    formData.append('Phone', message.phone);
    formData.append('Message', message.message);
    formData.append('App Type', appTypes);
    formData.append('Timestamp', new Date().toLocaleString('en-KE'));

    request.onload = () => {
      const { status, response } = request;
      const parsedResponse = JSON.parse(response);
      const { result } = parsedResponse;
      setLoading(false);

      if (status === 200 && result === 'success') {
        setSuccess(true);
        setServerErr(false);
        return;
      }

      setSuccess(false);
      setServerErr(true);

      setTimeout(() => {
        setServerErr(false);
      }, 5000);
    };

    request.send(formData);
  };

  return (
    <Context.Provider
      value={{
        message,
        setMessage,
        submitMessage,
        success,
        loading,
        page,
        serverErr
      }}
      {...props}
    />
  );
};
