import { Github } from '@styled-icons/boxicons-logos/Github';
import { Behance } from '@styled-icons/boxicons-logos/Behance';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { PinterestAlt } from '@styled-icons/boxicons-logos/PinterestAlt';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { ExternalLink } from '@styled-icons/evaicons-solid/ExternalLink';
import { ExpandMore } from '@styled-icons/material-rounded/ExpandMore';
import { HorizontalRule } from '@styled-icons/material-outlined/HorizontalRule';
import { BadgeCheck } from '@styled-icons/boxicons-regular/BadgeCheck';
import { Location } from '@styled-icons/ionicons-sharp/Location';
import { Connectdevelop } from '@styled-icons/fa-brands/Connectdevelop';
import { GearWideConnected } from '@styled-icons/bootstrap/GearWideConnected';

export const ProjectsData = {
  items: [
    {
      id: 1,
      name: 'shop.castynet.africa',
      type: 'online electronics store',
      link: 'https://shop.castynet.africa/',
      image: '../static/shop.genztech_logo.png',
      pp: 'project'
    },
    {
      id: 2,
      name: 'Digikids',
      type: 'E-Learning Portal',
      link: 'https://elearning.digikids.co.ke/',
      image: '../static/digikids_logo.png',
      pp: 'project'
    },
    {
      id: 5,
      name: 'Genztech',
      type: 'In-house Brand',
      link: 'https://castynet.africa/',
      image: '../static/genztech_logo.png',
      pp: 'project'
    },
    {
      id: 6,
      name: 'blog.castynet.africa',
      type: 'Genztech Blog',
      link: 'https://blog.castynet.africa/',
      image: '../static/shop.genztech_logo.png',
      pp: 'project'
    }
  ]
};

export const Icons = {
  Github: Github,
  Behance: Behance,
  Twitter: Twitter,
  Pinterest: PinterestAlt,
  Instagram: Instagram,
  Link: ExternalLink,
  Expand: ExpandMore,
  Dash: HorizontalRule,
  Check: BadgeCheck,
  Location: Location,
  Connect: Connectdevelop,
  Gear: GearWideConnected
};

export const Links = {
  Github: 'https://go.castynet.africa/Github',
  Behance: 'https://go.castynet.africa/Behance',
  Twitter: 'https://go.castynet.africa/twitter',
  Pinterest: 'https://go.castynet.africa/pinterest',
  Instagram: 'https://go.castynet.africa/instagram',
  GoogleListing: 'https://go.castynet.africa/google_listing'
};
