import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './app';
import { StateProvider } from './components/context/context';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';

const helmetContext = {};

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Router>
      <HelmetProvider context={helmetContext}>
        <StateProvider>
          <App />
        </StateProvider>
      </HelmetProvider>
    </Router>
  </StrictMode>
);
