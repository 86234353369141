import React, { useRef, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Icons } from '../helpers/utilities';
import Projects from '../projects/projects';

import * as Styled from './app.styles';

export default function ProjectsRoute() {
  const eProject = useRef(null),
    eAbout = useRef(null);

  useEffect(() => {
    eProject.current.scrollIntoView(true);
  }, []);

  return (
    <main>
      <section aria-label="Projects">
        <Link to="/">
          <Styled.Title>
            <Icons.Dash size="20" />
            <Styled.LineBg ref={eProject}>
              Projects &amp; Partners
            </Styled.LineBg>
          </Styled.Title>
        </Link>
        <Projects />
      </section>
      <section aria-label="Contact">
        <Link to="/contact">
          <Styled.Title>
            <Icons.Dash size="20" />
            <Styled.LineBg>Contact us</Styled.LineBg>
            <Icons.Expand size="30" color="#ff1493" />
          </Styled.Title>
        </Link>
      </section>
      <section aria-label="About">
        <Link
          to={{
            pathname: '/',
            state: { by: 'About' }
          }}
        >
          <Styled.Title>
            <Icons.Dash size="20" />
            <Styled.LineBg ref={eAbout}>About</Styled.LineBg>
            <Icons.Expand size="30" color="#ff1493" />
          </Styled.Title>
        </Link>
      </section>
    </main>
  );
}
