import styled from 'styled-components';

export const Wrapper = styled.section`
  margin: 100px auto 50px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  padding: 0 10px 10px;
  max-width: 100%;
`;

export const Description = styled.h2`
  font-size: 2.5em;
  letter-spacing: 1px;
  color: #304d5b;
  font-weight: 400;
  font-family: 'Teko', sans-serif;
  padding-bottom: 10px;
`;

export const Text = styled.p`
  font-family: 'Roboto', sans-serif;
  padding: 0 20px;
  line-height: 1.5em;
  font-size: 1.1em;
  text-align: center;
  color: #1f2762;
`;
