import styled from 'styled-components';

export const Wrapper = styled.footer`
  background-color: #1f2762;
  padding: 25px 0;
  margin-top: 40px;

  p {
    font-family: 'Roboto', sans-serif;
    color: var(--white);
    text-align: center;
    text-transform: lowercase;
    width: 100%;
    letter-spacing: 1px;
  }
`;

export const IconsWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-content: center;
  gap: 15px;
  max-width: 200px;
  margin: 15px auto 0 auto;
  border-top: 1px solid #ff1493;
  padding: 5px 20px 0 20px;

  a {
    color: var(--white);

    &:hover {
      color: #ff69b4;
    }
  }
`;
