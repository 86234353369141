import React from 'react';
import styled from 'styled-components';

export default function AnimatedLogo() {
  return (
    <SvgWrap
      width="420"
      height="59"
      viewBox="0 0 420 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="420" height="59" fill="transparent" />
      <g id="castynet svg 1">
        <g id="castynet">
          <path
            id="Vector"
            d="M35.33 18.26C33.76 16.85 32.14 15.82 30.49 15.17C28.84 14.52 27.07 14.19 25.18 14.19C21.31 14.19 18.22 15.35 15.92 17.66C13.62 19.98 12.47 23.09 12.47 26.99C12.47 30.81 13.54 33.84 15.67 36.09C17.8 38.33 20.72 39.45 24.41 39.45C26.51 39.45 28.46 39.1 30.27 38.4C32.07 37.7 33.74 36.64 35.28 35.24L35.23 43.21C33.58 44.19 31.84 44.91 30.03 45.39C28.22 45.87 26.31 46.11 24.31 46.11C18.52 46.11 13.77 44.31 10.05 40.71C6.34001 37.11 4.48001 32.54 4.48001 27C4.48001 21.47 6.34001 16.92 10.06 13.33C13.78 9.74 18.58 7.94 24.45 7.94C26.42 7.94 28.32 8.15 30.16 8.56C32 8.97 33.82 9.6 35.6 10.46L35.33 18.26Z"
            fill="black"
          />
          <path
            id="Vector_2"
            d="M59.27 20.79V24.81C58.35 23.2 57.2 22 55.84 21.18C54.47 20.37 52.89 19.97 51.09 19.97C47.63 19.97 44.93 21.11 42.98 23.41C41.04 25.7 40.07 28.89 40.07 33C40.07 36.94 41.06 40.1 43.03 42.5C45.01 44.9 47.61 46.1 50.84 46.1C52.77 46.1 54.41 45.74 55.76 45.02C57.11 44.3 58.27 43.17 59.23 41.63L59.13 45.13H65.98V20.79H59.27ZM57.91 38.06C56.83 39.28 55.32 39.9 53.37 39.9C51.45 39.9 49.95 39.28 48.86 38.06C47.78 36.84 47.24 35.12 47.24 32.92C47.24 30.72 47.79 28.99 48.89 27.72C49.99 26.46 51.5 25.83 53.42 25.83C55.29 25.83 56.77 26.46 57.87 27.74C58.97 29.01 59.52 30.74 59.52 32.92C59.52 35.12 58.98 36.84 57.91 38.06Z"
            fill="black"
          />
          <path
            id="Vector_3"
            d="M70.46 40.86L75.9 38.06C76.08 39 76.57 39.75 77.36 40.29C78.15 40.83 79.14 41.11 80.31 41.11C81.47 41.11 82.38 40.9 83.04 40.48C83.7 40.06 84.03 39.48 84.03 38.73C84.03 37.56 82.86 36.57 80.51 35.78C79.78 35.53 79.2 35.32 78.77 35.16C75.91 34.08 73.93 33 72.83 31.91C71.73 30.82 71.18 29.47 71.18 27.87C71.18 25.49 72.11 23.56 73.98 22.09C75.85 20.62 78.31 19.88 81.38 19.88C83.56 19.88 85.45 20.32 87.05 21.2C88.65 22.08 89.81 23.32 90.54 24.92L85.35 27.5C85.07 26.59 84.58 25.9 83.89 25.43C83.19 24.96 82.33 24.72 81.28 24.72C80.24 24.72 79.39 24.94 78.75 25.37C78.11 25.8 77.78 26.36 77.78 27.06C77.78 28.27 79.38 29.39 82.59 30.44C82.97 30.56 83.26 30.65 83.46 30.71C86.12 31.59 88.02 32.61 89.14 33.77C90.27 34.94 90.83 36.42 90.83 38.22C90.83 40.64 89.9 42.55 88.03 43.98C86.16 45.4 83.64 46.11 80.46 46.11C77.94 46.11 75.8 45.64 74.02 44.71C72.24 43.76 71.05 42.49 70.46 40.86Z"
            fill="black"
          />
          <path
            id="Vector_4"
            d="M98.7 45.13V26.32H95.3V20.79H98.7V12.7H105.78V20.79H109.18V26.32H105.78V45.13H98.7Z"
            fill="black"
          />
          <path
            id="Vector_5"
            d="M123.45 43.25L113.65 20.79H121.82L126.04 31.93C126.32 32.62 126.55 33.42 126.74 34.33C126.92 35.23 127.06 36.24 127.16 37.35C127.33 36.46 127.53 35.55 127.78 34.62C128.03 33.69 128.33 32.71 128.7 31.67L132.4 20.8H140.49L125.98 56.2H118.16L123.45 43.25Z"
            fill="black"
          />
          <path
            id="Vector_6"
            d="M144.95 45.13V20.79H151.87V24.61C153.14 23.02 154.51 21.85 155.98 21.1C157.45 20.35 159.07 19.97 160.86 19.97C162.75 19.97 164.33 20.29 165.61 20.93C166.89 21.57 167.91 22.54 168.65 23.85C169.01 24.53 169.27 25.29 169.43 26.15C169.59 27 169.67 28.34 169.67 30.16V30.93V45.15H162.6V33.1C162.6 30.11 162.25 28.13 161.56 27.17C160.87 26.21 159.67 25.73 157.99 25.73C157.01 25.73 156.12 25.93 155.3 26.34C154.48 26.75 153.8 27.32 153.25 28.06C152.82 28.64 152.51 29.3 152.32 30.05C152.13 30.8 152.03 31.93 152.03 33.45V34.42V45.14H144.95V45.13Z"
            fill="black"
          />
          <path
            id="Vector_7"
            d="M196.18 23.43C193.93 21.12 190.87 19.97 187 19.97C183.24 19.97 180.16 21.2 177.75 23.67C175.34 26.13 174.14 29.27 174.14 33.1C174.14 37.07 175.29 40.23 177.59 42.58C179.89 44.93 183.01 46.1 186.95 46.1C189.54 46.1 191.79 45.58 193.67 44.55C195.56 43.52 197.16 41.93 198.49 39.8L192.78 36.94C192.15 37.95 191.37 38.71 190.43 39.2C189.5 39.7 188.39 39.95 187.1 39.95C185.39 39.95 184.04 39.51 183.05 38.64C182.06 37.77 181.42 36.46 181.14 34.71H199.43C199.48 34.21 199.51 33.84 199.53 33.58C199.55 33.32 199.55 33.09 199.55 32.87C199.55 28.89 198.43 25.74 196.18 23.43ZM181.36 30.44C181.63 28.9 182.24 27.74 183.19 26.95C184.14 26.17 185.41 25.78 187 25.78C188.58 25.78 189.84 26.17 190.76 26.95C191.67 27.74 192.23 28.9 192.43 30.44H181.36V30.44Z"
            fill="black"
          />
          <path
            id="Vector_8"
            d="M207.42 45.13V26.32H204.02V20.79H207.42V12.7H214.49V20.79H217.89V26.32H214.49V45.13H207.42Z"
            fill="black"
          />
        </g>
        <g id="studios">
          <path
            id="Vector_9"
            d="M236.16 40.52L240.48 35.63C241.03 36.97 241.9 38.03 243.11 38.79C244.32 39.56 245.67 39.94 247.18 39.94C249 39.94 250.44 39.51 251.5 38.65C252.56 37.79 253.09 36.63 253.09 35.18C253.09 33.46 251.33 31.75 247.8 30.04C246.66 29.49 245.76 29.05 245.09 28.7C241.91 27.05 239.71 25.43 238.49 23.85C237.27 22.27 236.65 20.39 236.65 18.2C236.65 15.21 237.84 12.74 240.21 10.82C242.58 8.89 245.67 7.93 249.45 7.93C251.65 7.93 253.55 8.31 255.15 9.08C256.75 9.85 258.16 11.05 259.38 12.69L254.79 17.08C254.24 16.02 253.52 15.23 252.62 14.71C251.72 14.19 250.63 13.93 249.36 13.93C247.82 13.93 246.58 14.31 245.64 15.08C244.7 15.85 244.22 16.85 244.22 18.07C244.22 19.99 246.09 21.74 249.83 23.33C250.58 23.64 251.15 23.89 251.54 24.07C254.62 25.48 256.9 27.06 258.38 28.84C259.86 30.62 260.6 32.63 260.6 34.9C260.6 38.31 259.44 41.03 257.11 43.05C254.79 45.08 251.66 46.09 247.74 46.09C244.96 46.09 242.58 45.61 240.59 44.65C238.61 43.7 237.13 42.32 236.16 40.52Z"
            fill="black"
          />
          <path
            id="Vector_10"
            d="M269.88 45.13V26.32H266.48V20.79H269.88V12.7H276.95V20.79H280.35V26.32H276.95V45.13H269.88Z"
            fill="black"
          />
          <path
            id="Vector_11"
            d="M293.26 20.79V33.6C293.26 35.75 293.68 37.29 294.51 38.22C295.34 39.15 296.71 39.61 298.59 39.61C300.46 39.61 301.82 39.14 302.66 38.21C303.5 37.28 303.93 35.74 303.93 33.61V20.79H311V33.6C311 37.89 310 41.04 308 43.07C306 45.1 302.9 46.11 298.69 46.11C294.4 46.11 291.26 45.1 289.25 43.08C287.24 41.06 286.23 37.9 286.23 33.6V20.79H293.26Z"
            fill="black"
          />
          <path
            id="Vector_12"
            d="M336.09 6.67V20.54C336.09 21.47 336.11 22.29 336.15 23.01C336.19 23.73 336.25 24.39 336.33 24.98C335.16 23.23 333.9 21.95 332.56 21.13C331.22 20.32 329.67 19.92 327.9 19.92C324.44 19.92 321.74 21.07 319.79 23.37C317.85 25.67 316.88 28.88 316.88 33C316.88 36.94 317.87 40.1 319.84 42.5C321.82 44.9 324.42 46.1 327.65 46.1C329.58 46.1 331.23 45.74 332.57 45.02C333.92 44.3 335.08 43.17 336.04 41.63L335.94 45.13H342.79V6.67H336.09ZM334.72 38.06C333.65 39.28 332.13 39.9 330.18 39.9C328.26 39.9 326.76 39.28 325.68 38.04C324.59 36.79 324.05 35.07 324.05 32.87C324.05 30.67 324.6 28.95 325.7 27.7C326.8 26.45 328.31 25.83 330.23 25.83C332.1 25.83 333.58 26.46 334.68 27.74C335.78 29.01 336.33 30.74 336.33 32.92C336.33 35.12 335.8 36.84 334.72 38.06Z"
            fill="black"
          />
          <g id="Group">
            <path
              id="Vector_13"
              d="M348.67 10.79C348.67 9.66 349.08 8.7 349.89 7.89C350.7 7.08 351.67 6.67 352.79 6.67C353.93 6.67 354.91 7.07 355.73 7.87C356.55 8.67 356.96 9.64 356.96 10.79C356.96 11.94 356.55 12.91 355.73 13.72C354.91 14.53 353.93 14.94 352.79 14.94C351.66 14.94 350.7 14.53 349.89 13.7C349.08 12.86 348.67 11.89 348.67 10.79Z"
              fill="black"
            />
            <path
              id="Vector_14"
              d="M356.34 20.79H349.29V45.14H356.34V20.79Z"
              fill="black"
            />
          </g>
          <path
            id="Vector_15"
            d="M386.19 23.67C383.65 21.2 380.4 19.97 376.46 19.97C372.54 19.97 369.29 21.22 366.71 23.72C364.13 26.21 362.84 29.32 362.84 33.05C362.84 36.79 364.12 39.9 366.69 42.38C369.25 44.86 372.51 46.1 376.46 46.1C380.39 46.1 383.62 44.86 386.18 42.38C388.74 39.9 390.01 36.79 390.01 33.05C390.01 29.26 388.74 26.13 386.19 23.67ZM381.07 37.76C379.9 38.95 378.35 39.55 376.41 39.55C374.46 39.55 372.92 38.95 371.77 37.76C370.63 36.57 370.06 34.98 370.06 33C370.06 31 370.63 29.41 371.77 28.23C372.92 27.06 374.46 26.47 376.41 26.47C378.37 26.47 379.92 27.06 381.08 28.24C382.24 29.43 382.82 31.01 382.82 33C382.82 34.98 382.24 36.57 381.07 37.76Z"
            fill="black"
          />
          <path
            id="Vector_16"
            d="M395.89 40.86L401.33 38.06C401.51 39 402 39.75 402.79 40.29C403.58 40.83 404.57 41.11 405.74 41.11C406.9 41.11 407.81 40.9 408.47 40.48C409.13 40.06 409.46 39.48 409.46 38.73C409.46 37.56 408.29 36.57 405.94 35.78C405.21 35.53 404.63 35.32 404.2 35.16C401.34 34.08 399.36 33 398.26 31.91C397.16 30.82 396.61 29.47 396.61 27.87C396.61 25.49 397.54 23.56 399.41 22.09C401.28 20.62 403.74 19.88 406.81 19.88C408.99 19.88 410.88 20.32 412.48 21.2C414.08 22.08 415.24 23.32 415.97 24.92L410.78 27.5C410.5 26.59 410.01 25.9 409.32 25.43C408.62 24.96 407.76 24.72 406.71 24.72C405.67 24.72 404.82 24.94 404.18 25.37C403.54 25.8 403.21 26.36 403.21 27.06C403.21 28.27 404.81 29.39 408.02 30.44C408.4 30.56 408.69 30.65 408.89 30.71C411.55 31.59 413.45 32.61 414.57 33.77C415.7 34.94 416.26 36.42 416.26 38.22C416.26 40.64 415.33 42.55 413.46 43.98C411.59 45.4 409.07 46.11 405.89 46.11C403.37 46.11 401.23 45.64 399.45 44.71C397.67 43.76 396.48 42.49 395.89 40.86Z"
            fill="black"
          />
        </g>
      </g>
    </SvgWrap>
  );
}

const SvgWrap = styled.svg`
  max-width: 100%;

  path {
    fill: inherit;
  }

  #castynet {
    fill: transparent;
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 8px;
    stroke: rgb(31, 39, 98);
    animation: 3s draw linear forwards;
    animation-delay: 0.5s;
  }

  #studios {
    fill: transparent;
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 8px;
    stroke: rgb(31, 39, 98);
    animation: 3.5s draw linear forwards;
    animation-delay: 1s;
  }

  @keyframes draw {
    0% {
      stroke-dasharray: 140 540;
      stroke-dashoffset: -474;
      stroke-width: 8px;
      fill: rgb(48 77 91);
    }
    20% {
      fill: rgb(48 77 91);
    }
    66.6% {
      stroke-dasharray: 760;
      stroke-dashoffset: 0;
      stroke-width: 1.5px;
      fill-opacity: 0;
      fill: rgb(31, 39, 98);
    }
    100% {
      stroke-dasharray: 760;
      stroke-dashoffset: 0;
      stroke-width: 0;
      fill: rgb(31, 39, 98);
    }
  }
`;
